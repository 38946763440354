
import { Timesheet } from '@/models/timesheet';
import Vue from 'vue';

enum Component {
  DATATABLE = 'data-timesheets-timesheets-data-table',
  TIMESHEET = 'data-timesheets-timesheet-detail-form'
}
export default Vue.extend({
  metaInfo: {
    title: 'Timesheets - '
  },
  name: 'Timesheets',
  computed: {}
  // data: () => ({
  //   currentComponent: Component.DATATABLE,
  //   editTimesheet: [] as any
  // }),
  // methods: {
  //   swapComponent: function(component: string) {
  //     if (component == 'data-timesheets-timesheets-data-table') {
  //       this.currentComponent = Component.DATATABLE;
  //     } else {
  //       this.currentComponent = Component.TIMESHEET;
  //     }
  //   },
  //   getEditTimesheet(created: any, timesheet: Timesheet) {
  //     this.editTimesheet = timesheet;
  //   }
  // },
  // async mounted() {
  //   this.getEditTimesheet;
  // }
});
